import React from 'react';

const Page404: React.FC = () => {
  return (
    <div className="text-black h-[100vh] text-center flex flex-col items-center justify-center bg-grayscale-gray-50">
      <div>
        <h1 className="inline-block border-solid border-0 border-r m-0 mr-[20px] py-[10px] pr-[23px] text-24 font-medium align-top">
          404
        </h1>
        <div className="inline-block text-left leading-[49px] h-[49px] align-middle">
          <h2 className="text-14 font-normal m-0 p-0">This page could not be found</h2>
        </div>
      </div>
    </div>
  );
};

export default Page404;
